<template functional>
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.5 10.5938C5.5 11.6847 6.409 12.5938 7.5 12.5938C8.591 12.5938 9.5 11.6847 9.5 10.5938C9.5 9.50275 8.591 8.59375 7.5 8.59375C6.409 8.59375 5.5 9.50275 5.5 10.5938Z"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 10H18"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 13.5H14"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 17.0408C10.83 16.6138 10.567 16.2298 10.229 15.9168V15.9168C9.658 15.3878 8.908 15.0938 8.129 15.0938H6.871C6.092 15.0938 5.342 15.3878 4.771 15.9168V15.9168C4.433 16.2298 4.17 16.6138 4 17.0408"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 6H10C9.448 6 9 5.552 9 5V2C9 1.448 9.448 1 10 1H12C12.552 1 13 1.448 13 2V5C13 5.552 12.552 6 12 6Z"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 4H3.041C1.914 4 1 4.914 1 6.041V18C1 19.105 1.895 20 3 20H19C20.105 20 21 19.105 21 18V6C21 4.895 20.105 4 19 4H13"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconMenu1",
};
</script>

<style></style>
